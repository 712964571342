<template>
  <Head :title="__('account.payout-settings.title')" />

  <Breadcrumbs
    :pages="[
      { name: __('account.overview.title'), href: route('frontend.account') },
    ]"
    :current-page="__('account.payout-settings.title')"
  />

  <section class="mb-6 md:mb-12">
    <div class="container">
      <div class="flex flex-col lg:flex-row">
        <div class="flex flex-col lg:w-4/12 lg:mr-8">
          <h1 class="text-2xl md:text-3xl font-bold mb-4 md:mb-2.5">
            {{ __('account.payout-settings.title') }}
          </h1>

          <div class="hidden lg:flex flex-col">
            <p class="text-gray-700 mb-5">
              {{ __('account.payout-settings.subtitle') }}
            </p>

            <img
              class="inline w-1/2 opacity-10"
              src="/qassa/images/payout-settings.svg"
            >
          </div>
        </div>

        <div class="flex flex-col w-full xl:flex-row lg:w-8/12">
          <div class="mb-4 xl:mb-0 xl:w-1/2 xl:order-last">
            <h2 class="text-lg font-bold mb-4">
              {{ __('account.payout-settings.existing') }}
            </h2>

            <div class="flex flex-col space-y-5 xl:space-y-7">
              <div
                v-for="(bankAccount, i) in bankAccounts"
                :key="`bank-account-${i}`"
                class="flex flex-col relative bg-white rounded shadow p-4"
              >
                <DeleteBankAccountModal :bank-account-id="bankAccount.id" />

                <span
                  v-if="bankAccount.isVerified"
                  class="absolute -top-2 text-2xs text-white font-bold bg-blue rounded px-2.5 py-0.5"
                >
                  {{ __('account.payout-settings.bank-account.verified') }}
                </span>
                <span
                  v-else
                  class="absolute -top-2 text-2xs text-white font-bold bg-gray-600 rounded px-2.5 py-0.5"
                >
                  {{ __('account.payout-settings.bank-account.not-verified') }}
                </span>

                <span class="mb-2">
                  {{ bankAccount.iban }}
                </span>

                <span class="text-sm text-gray-700">
                  {{ bankAccount.name }}
                </span>

                <div>
                  <Link
                    v-if="!bankAccount.isVerified"
                    method="post"
                    :data="{id: bankAccount.id}"
                    :href="route('frontend.account.payout.bank.resend-verification')"
                    as="button"
                    class="text-blue underline hover:no-underline text-sm mt-2.5"
                  >
                    {{ __('account.payout-settings.bank-account.resend-verification-mail') }}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full xl:w-1/2 xl:mr-6">
            <h2 class="text-lg font-bold mb-1 xl:mb-4">
              {{ __('account.payout-settings.new') }}
            </h2>

            <div class="xl:bg-white xl:shadow rounded xl:p-6">
              <form
                class="form md:mt-4"
                @submit.prevent="form.post(route('frontend.account.payout.bank.store'), {
                  onSuccess: () => form.reset(),
                })"
              >
                <div class="flex flex-col space-y-4">
                  <div class="flex flex-col">
                    <label class="text-sm mb-1 w-full pr-0">
                      {{ __('general.bank-account-holder-name') }}:
                    </label>
                    <input
                      v-model="form.name"
                      type="text"
                      autocomplete="name"
                      required
                    >
                    <p
                      v-if="form.errors.name"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.name }}
                    </p>
                  </div>

                  <div class="flex flex-col">
                    <label class="text-sm mb-1 w-full pr-0">
                      {{ __('general.iban') }}:
                    </label>
                    <input
                      v-model="iban"
                      type="text"
                      name="iban"
                      autocomplete="cc-number"
                      required
                    >
                    <p
                      v-if="form.errors.iban"
                      class="text-orange text-xs italic mt-0.5"
                    >
                      {{ form.errors.iban }}
                    </p>
                  </div>

                  <div class="flex justify-end">
                    <div class="w-full xl:w-auto">
                      <Blue
                        :disabled="form.processing"
                      >
                        {{ __('account.payout-settings.form.button') }}
                      </Blue>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {useForm} from '@inertiajs/vue3';
import Blue from '../../../Components/Buttons/Blue.vue';
import DeleteBankAccountModal from '../../../Components/DeleteBankAccountModal.vue';

export default {
  components: {
    Blue, DeleteBankAccountModal,
  },

  props: {
    bankAccounts: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const form = useForm({
      name: '',
      iban: '',
    });

    return {form};
  },

  computed: {
    iban: {
      get () {
        return this.form.iban;
      },

      set (value) {
        this.form.iban = value.replace(/\s/g, '').toUpperCase();
      },
    },
  },
};
</script>
