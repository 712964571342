<template>
  <section class="flex items-center bg-blue w-full h-14 overflow-hidden">
    <div class="md:container pl-[15px] flex items-center text-white w-full">
      <div class="relative flex text-lg font-bold leading-[1.125rem] pr-5">
        ZORG<br>
        2024
        <img
          class="absolute right-0 -bottom-1"
          src="/qassa/images/care.svg"
          alt=""
        >
      </div>

      <div
        class="flex justify-between space-x-4 md:space-x-8 w-full overflow-x-scroll ml-4 my-4 py-4 pr-6 md:pr-0"
        :class="showTimer ? 'lg:overflow-x-auto' : 'md:overflow-x-auto'"
      >
        <div class="flex space-x-4">
          <Link
            :href="route('frontend.webshop-theme.show', 'health-care-overview')"
            class="text-white no-underline hover:underline"
          >
            Zorgoverzicht
          </Link>

          <Link
            :href="route('frontend.care-tips.index')"
            class="text-white no-underline hover:underline"
          >
            Zorgtips
          </Link>

          <Link
            :href="route('frontend.faq.category', 9)"
            class="text-white no-underline hover:underline whitespace-nowrap"
          >
            Zorg FAQ
          </Link>

          <Link
            :href="route('frontend.webshop-theme.show', 'health-care-commercials')"
            class="text-white no-underline hover:underline"
          >
            Zorgcommercials
          </Link>
        </div>

        <div
          v-if="showTimer"
          class="whitespace-nowrap"
        >
          Nog
          {{ days }} <span v-if="days === 1">dag</span><span v-else>dagen</span>
          {{ hours }} uur
          {{ minutes }} <span v-if="minutes === 1">minuut</span><span v-else>minuten</span>
          en {{ seconds }} <span v-if="seconds === 1">seconde</span><span v-else>seconden</span>
        </div>
      </div>

      <span
        class="material-symbols-outlined md-36 absolute right-1.5 -bottom-5 text-orange font-bold"
        :class="showTimer ? 'lg:hidden' : 'md:hidden'"
      >
        swipe
      </span>
    </div>
  </section>
</template>

<script>
import {ref} from 'vue';

export default {
  data() {
    const timerEndDate = new Date('2024-01-01T00:00:00');

    return {
      timerEndDate: timerEndDate,
      showTimer: ref(false),
      days: ref(0),
      hours: ref(0),
      minutes: ref(0),
      seconds: ref(0),
    };
  },

  mounted: function () {
    this.setTimerData();
    setInterval(() => {
      this.setTimerData();
    }, 1000);
  },

  methods: {
    setTimerData() {
      this.showTimer = new Date() < this.timerEndDate;
      if (!this.showTimer) {
        return;
      }

      let diffTime = Math.abs(new Date().valueOf() - this.timerEndDate.valueOf());
      let days = diffTime / (24 * 60 * 60 * 1000);
      let hours = (days % 1) * 24;
      let minutes = (hours % 1) * 60;
      let seconds = (minutes % 1) * 60;
      [this.days, this.hours, this.minutes, this.seconds] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(seconds)];
    },
  },
};
</script>

