<template>
  <QassaHead />

  <header
    class="bg-white w-full sticky top-0 z-40"
    :class="{ 'drop-shadow-xl': isScrolling }"
  >
    <NotificationBar />

    <div class="container relative flex justify-between items-center">
      <div class="py-3.5 md:py-5">
        <Link :href="route('frontend.index')">
          <img
            class="h-6 md:h-auto"
            src="/qassa/images/logo.svg"
            :alt="__('tenant.name')"
          >
        </Link>
      </div>

      <div
        v-if="!$page.props.auth.member"
        class="flex items-center space-x-3 md:space-x-4"
      >
        <LoginModal ref="loginModal" />

        <Link
          class="text-xs md:text-base text-orange md:hover:text-white md:hover:bg-orange md:no-underline md:ring-1 md:ring-orange md:rounded-full md:px-5 md:py-1"
          :href="route('frontend.register')"
        >
          {{ __('header.menu.register') }}
        </Link>
      </div>

      <div v-else>
        <AlreadyParticipatedModal ref="alreadyParticipated" />
        <LogoutModal
          ref="logoutModal"
          :unread-emails-count="$page.props.unreadEmailsCount"
        />

        <div class="flex justify-center items-center lg:hidden space-x-4">
          <Link :href="route('frontend.mailings.index')">
            <div class="relative">
              <img
                height="16"
                width="21"
                src="/qassa/images/mail_icon.svg"
              >
              <div
                class="bg-blue rounded-full w-4 text-center text-white text-3xs font-medium absolute top-0 -right-0 -mt-1 -mr-1.5 p-0.5"
              >
                {{ $page.props.unreadEmailsCount }}
              </div>
            </div>
          </Link>

          <Popover
            v-slot="{ open }"
            as="div"
          >
            <PopoverButton
              as="div"
              class="leading-none cursor-pointer"
            >
              <img
                height="18"
                width="18"
                src="/qassa/images/user_icon.svg"
              >
            </PopoverButton>

            <PopoverPanel
              v-slot="{ close }"
              as="div"
              :open="open"
              class="z-50 absolute top-[52px] pb-[52px] left-0 w-screen h-dvh bg-white overflow-y-scroll"
            >
              <div class="flex flex-col font-medium divide-y divide-gray-300">
                <div class="py-3 px-4 border-t border-gray-300 text-center font-bold text-lg">
                  {{ __('header.menu.welcome-name', {name: $page.props.auth.member.first_name}) }}
                </div>

                <Link
                  v-for="theme in $page.props.menuWebshopThemes"
                  :key="`active-webshop-theme-${theme.slug}`"
                  :href="route('frontend.webshop-theme.show', theme.slug)"
                  class="flex items-center justify-between group py-3 px-4 no-style font-bold"
                  @click="close"
                >
                  {{ theme.name }}
                </Link>

                <Link
                  :href="route('frontend.famous-webshops')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.top-shops') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.favorite-webshops')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.favorite-shops') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.balance')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <div class="flex items-center">
                    <span class="group-hover:underline">
                      {{ __('header.menu.balance') }}
                    </span>
                    <span class="ml-2 text-xs text-blue">
                      ({{ $page.props.auth.member.balance }})
                    </span>
                  </div>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.mailings.index')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <div class="flex items-center">
                    <span class="group-hover:underline">
                      {{ __('header.menu.read-emails') }}
                    </span>
                    <span class="ml-2 text-xs text-blue">
                      ({{ __('general.unread_emails', {unreadEmails: $page.props.unreadEmailsCount}) }})
                    </span>
                  </div>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.free-actions.index')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.free-actions') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.games.index')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.games') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.account')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.account-settings') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.tell-a-friend.index')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.tell-a-friend') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  :href="route('frontend.profile-questions.index')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <div class="flex items-center">
                    <span class="group-hover:underline">
                      {{ __('header.menu.profile-questions') }}
                    </span>
                    <span
                      v-if="$page.props.openProfileQuestionsCount > 0"
                      class="ml-2 text-xs text-blue"
                    >
                      ({{ __('header.menu.open-profile-questions', {count: $page.props.openProfileQuestionsCount}) }})
                    </span>
                  </div>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  v-if="$page.props.availablePuzzleCount > 0"
                  :href="route('frontend.puzzles')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.puzzles') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  v-if="$page.props.hasActiveBlogs"
                  :href="route('frontend.blogs')"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.blogs') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>

                <Link
                  v-if="$page.props.unreadEmailsCount === 0"
                  :href="route('frontend.logout')"
                  method="post"
                  as="button"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="close"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.logout') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </Link>
                <div
                  v-else
                  as="button"
                  class="flex items-center justify-between group py-3 px-4 no-style"
                  @click="$refs.logoutModal.openModal()"
                >
                  <span class="group-hover:underline">
                    {{ __('header.menu.logout') }}
                  </span>
                  <img src="/qassa/images/orange-arrow.svg">
                </div>
              </div>
            </PopoverPanel>
          </Popover>
        </div>

        <div class="hidden lg:flex space-x-6">
          <Link
            v-for="theme in $page.props.menuWebshopThemes"
            :key="`active-webshop-theme-${theme.slug}`"
            class="text-gray-900 no-underline hover:font-bold before:block before:content-[attr(title)] before:font-bold before:h-0 before:overflow-hidden before:invisible font-bold"
            :href="route('frontend.webshop-theme.show', theme.slug)"
            :title="theme.name"
          >
            {{ theme.name }}
          </Link>

          <Link
            class="text-gray-900 no-underline hover:font-bold before:block before:content-[attr(title)] before:font-bold before:h-0 before:overflow-hidden before:invisible"
            :class="{ 'font-bold': $page.component.startsWith('FamousWebshops/') }"
            :href="route('frontend.famous-webshops')"
            :title="__('header.menu.top-shops')"
          >
            {{ __('header.menu.top-shops') }}
          </Link>
          <Link
            class="text-gray-900 no-underline hover:font-bold before:block before:content-[attr(title)] before:font-bold before:h-0 before:overflow-hidden before:invisible"
            :class="{ 'font-bold': $page.component.startsWith('FreeActions/') }"
            :href="route('frontend.free-actions.index')"
            :title="__('header.menu.free-actions')"
          >
            {{ __('header.menu.free-actions') }}
          </Link>
          <Link
            class="text-gray-900 no-underline hover:font-bold before:block before:content-[attr(title)] before:font-bold before:h-0 before:overflow-hidden before:invisible"
            :class="{ 'font-bold': $page.component.startsWith('Games/') }"
            :href="route('frontend.games.index')"
            :title="__('header.menu.games')"
          >
            {{ __('header.menu.games') }}
          </Link>
          <Link
            class="text-gray-900 no-underline hover:font-bold before:block before:content-[attr(title)] before:font-bold before:h-0 before:overflow-hidden before:invisible"
            :class="{ 'font-bold': $page.component.startsWith('Mailing/') }"
            :href="route('frontend.mailings.index')"
            :title="`${__('header.menu.read-emails')} (${$page.props.unreadEmailsCount})`"
          >
            {{ __('header.menu.read-emails') }}
            <span class="text-blue text-sm">({{ $page.props.unreadEmailsCount }})</span>
          </Link>
          <Link
            class="text-gray-900 no-underline hover:font-bold before:block before:content-[attr(title)] before:font-bold before:h-0 before:overflow-hidden before:invisible"
            :class="{ 'font-bold': $page.component.startsWith('FavoriteWebshop/') }"
            :href="route('frontend.favorite-webshops')"
            :title="__('header.menu.my-shops')"
          >
            {{ __('header.menu.my-shops') }}
          </Link>

          <Popover v-if="$page.props.auth.member">
            <PopoverButton
              as="div"
              class="text-gray-900 no-underline cursor-pointer"
            >
              <div class="flex items-center">
                <img
                  height="20"
                  width="20"
                  class="mr-2"
                  :src="$page.props.auth.member.picture ?? '/qassa/images/user_icon.svg'"
                >
                {{ __('header.menu.welcome') }} {{ $page.props.auth.member.first_name }}
                <svg
                  class="ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="10.828"
                  height="6.414"
                  viewBox="0 0 10.828 6.414"
                >
                  <path
                    id="Path_89"
                    data-name="Path 89"
                    d="M10,16l4-4L10,8"
                    transform="translate(17.414 -8.586) rotate(90)"
                    fill="none"
                    stroke="currentcolor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                  />
                </svg>
              </div>
            </PopoverButton>
            <PopoverPanel
              v-slot="{ close }"
              as="div"
              class="z-50 absolute right-0 flex flex-col whitespace-nowrap bg-white ring-1 ring-orange shadow mt-4"
            >
              <div class="flex flex-col divide-y divide-gray-300">
                <Link
                  :href="route('frontend.balance')"
                  class="no-underline p-4 hover:bg-gray-100"
                  @click="close"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('header.menu.balance') }} <span class="ml-3 text-xs text-blue">({{ $page.props.auth.member.balance }})</span>
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <Link
                  :href="route('frontend.account')"
                  class="no-underline p-4 hover:bg-gray-100"
                  @click="close"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('header.menu.account-settings') }}
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <Link
                  :href="route('frontend.tell-a-friend.index')"
                  class="no-underline p-4 hover:bg-gray-100"
                  @click="close"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('header.menu.tell-a-friend') }}
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <Link
                  :href="route('frontend.profile-questions.index')"
                  class="no-underline p-4 hover:bg-gray-100"
                  @click="close"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('header.menu.profile-questions') }}
                      <span
                        v-if="$page.props.openProfileQuestionsCount > 0"
                        class="ml-3 text-xs text-blue"
                      >
                        ({{ __('header.menu.open-profile-questions', {count: $page.props.openProfileQuestionsCount}) }})
                      </span>
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <Link
                  v-if="$page.props.availablePuzzleCount > 0"
                  :href="route('frontend.puzzles')"
                  class="no-underline p-4 hover:bg-gray-100"
                  @click="close"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('header.menu.puzzles') }}
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <Link
                  v-if="$page.props.hasActiveBlogs"
                  :href="route('frontend.blogs')"
                  class="no-underline p-4 hover:bg-gray-100"
                  @click="close"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('header.menu.blogs') }}
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <Link
                  v-if="$page.props.unreadEmailsCount === 0"
                  :href="route('frontend.logout')"
                  class="no-underline p-4 hover:bg-gray-100"
                  method="post"
                  as="button"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('general.logout') }}
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </Link>

                <div
                  v-else
                  as="button"
                  class="no-underline p-4 hover:bg-gray-100 hover:cursor-pointer"
                  @click="$refs.logoutModal.openModal()"
                >
                  <div class="flex justify-between">
                    <div class="flex items-center text-black">
                      {{ __('general.logout') }}
                    </div>
                    <div class="ml-10 text-orange">
                      <span class="material-symbols-outlined">chevron_right</span>
                    </div>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </Popover>
        </div>
      </div>
    </div>

    <section class="bg-orange w-full">
      <div class="container flex justify-center items-center">
        <div class="flex relative w-full md:w-4/6 xl:w-1/2 py-3.5 md:py-2">
          <SearchBar
            :block-style="true"
            :input-placeholder="__('webshop-search.placeholder', {count: $page.props.webshopCount})"
          />
        </div>
      </div>
    </section>

    <CareMenu v-if="$page.props.showCareMenu" />
  </header>

  <FlashMessages />

  <main class="mb-auto">
    <slot />
  </main>

  <QassaFooter :country="country" />
</template>

<script>
import {ref} from 'vue';
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue';
import QassaHead from './Components/Head.vue';
import QassaFooter from './Components/Footer.vue';
import LoginModal from '../Components/LoginModal.vue';
import LogoutModal from '../Components/LogoutModal.vue';
import AlreadyParticipatedModal from '../Components/AlreadyParticipatedModal.vue';
import SearchBar from '../Components/Webshop/SearchBar.vue';
import FlashMessages from './Components/FlashMessages.vue';
import CareMenu from './Components/CareMenu.vue';
import NotificationBar from '../Components/NotificationBar.vue';

export default {
  components: {
    QassaHead,
    QassaFooter,
    Popover,
    PopoverButton,
    PopoverPanel,
    LoginModal,
    LogoutModal,
    AlreadyParticipatedModal,
    SearchBar,
    FlashMessages,
    CareMenu,
    NotificationBar,
  },

  props: {
    displayLogoutModal: {
      type: Boolean,
      default: false,
    },

    country: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      isScrolling: ref(false),
    };
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  },

  methods: {
    onScroll() {
      this.isScrolling = window.top.scrollY > 0;
    },
  },
};
</script>
