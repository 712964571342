import {createSSRApp, h} from 'vue';
import {createInertiaApp, Link} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ZiggyVue} from 'ziggy';
import {createI18n} from 'vue-i18n';
import Layout from './Layout/Default.vue';
import Head from './Layout/Components/Head.vue';
import Breadcrumbs from './Components/Breadcrumbs.vue';
import BugsnagPlugin from '../../global/js/bugsnag';
import ResponsiveHelpersPlugin from '../../global/js/responsiveHelpers';
import {default as helpers} from '../../global/js/helpers';
import '../sass/app.scss';

createInertiaApp({
  progress: {
    color: '#FF6501',
  },
  resolve: name => {
    return resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', { eager: true }))
      .then(page => {
        page.default.layout = page.default.layout || Layout;
        return page;
      });
  },
  setup({el, App, props, plugin}) {
    const ssrWorking = el.childNodes.length > 0;
    if (!props.initialPage.props.isProduction && !ssrWorking) {
      const error = 'app.js - SSR not working?! Check the (docker) logs for any SSR error. Preventing the app from client-side rendering.';
      console.error(error);

      const errorElement = document.createElement('div');
      errorElement.innerHTML = `<h1><b>${error}</b></h1>`;
      el.appendChild(errorElement);

      throw error;
    }

    const createdApp = createSSRApp({render: () => h(App, props)});

    createdApp
      .use(plugin)
      .use(ZiggyVue)
      .use(createI18n({
        legacy: false,
        globalInjection: true,
        locale: props.initialPage.props.i18n.locale,
        fallbackLocale: props.initialPage.props.i18n.fallback_locale,
        messages: props.initialPage.props.i18n.translations,
      }))
      .use(BugsnagPlugin)
      .use(ResponsiveHelpersPlugin)
      .mixin(helpers)
      .component('Head', Head)
      .component('Link', Link)
      .component('Breadcrumbs', Breadcrumbs)
      .mount(el);
  },
});
